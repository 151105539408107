






import { Component, Vue } from 'vue-property-decorator'
import { Action } from 'vuex-class'
@Component
export default class App extends Vue {
    @Action('getPermission') getPermission!: () => void
    @Action('getConfig') getConfig!: () => void
    async created() {
        this.getPermission()
        const data: any = await this.getConfig()
        let favicon: any = document.querySelector('link[rel="icon"]')!
        if (favicon) {
            favicon.href = data.favicon
            return
        }
        favicon = document.createElement('link')
        favicon.rel = 'icon'
        favicon.href = data.favicon
        document.head.appendChild(favicon)
    }
}
