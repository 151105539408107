import Main from '@/layout/main.vue'

const routes = [
    {
        path: '/finance',
        name: 'finance',
        meta: { title: '财务' },
        redirect: '/finance/profile',
        component: Main,
        children: [
            {
                path: '/finance/profile',
                name: 'profile',
                meta: {
                    title: '财务概况',
                    parentPath: '/finance',
                    icon: 'icon_caiwu',
                    permission: ['view']
                },
                component: () => import('@/views/finance/profile.vue')
            },
            {
                path: '/finance/daily_statistics',
                name: 'daily_statistics',
                meta: {
                    title: '日报统计',
                    parentPath: '/finance',
                    icon: 'icon_shuju',
                    permission: ['view']
                },
                component: () => import('@/views/finance/daily_statistics.vue')
            },
            {
                path: '/finance/user_withdrawal',
                name: 'user_withdrawal',
                meta: {
                    title: '提现记录',
                    parentPath: '/finance',
                    icon: 'icon_caiwu_tixian',
                    permission: ['view']
                },
                component: () => import('@/views/finance/user_withdrawal.vue')
            },
            {
                path: '/finance/account_log',
                name: 'account_log',
                meta: {
                    title: '余额明细',
                    parentPath: '/finance',
                    icon: 'icon_caiwu_yue',
                    permission: ['view']
                },
                component: () => import('@/views/finance/account_log.vue')
            },
            // {
            //     path: '/finance/integral_list',
            //     name: 'integral_list',
            //     meta: {
            //         title: '积分明细',
            //         parentPath: '/finance',
            //         icon: 'icon_caiwu_jifen',
            //         permission: ['view']
            //     },
            //     component: () => import('@/views/finance/integral_list.vue')
            // },
            {
                path: '/finance/commission_log',
                name: 'commission_log',
                meta: {
                    title: '佣金明细',
                    parentPath: '/finance',
                    icon: 'icon_set_jiaoyi',
                    permission: ['view']
                },
                component: () => import('@/views/finance/commission_log.vue')
            },
            {
                path: '/finance/daily_delivery_reports',
                name: 'daily_delivery_reports',
                meta: {
                    title: '日配送报表',
                    parentPath: '/finance',
                    icon: 'icon_order_guanli',
                    permission: ['view']
                },
                component: () => import('@/views/finance/daily_delivery_reports.vue')
            },
            {
                path: '/finance/daily_delivery_financial',
                name: 'daily_delivery_financial',
                meta: {
                    title: '日配送财务报表',
                    parentPath: '/finance',
                    icon: 'icon_order_guanli',
                    permission: ['view']
                },
                component: () => import('@/views/finance/daily_delivery_financial.vue')
            }
        ]
    }
]

export default routes
