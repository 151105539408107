import Main from '@/layout/main.vue'
import Blank from '@/layout/blank.vue'
import DecorateLayout from '@/layout/decorate.vue'
const routes = [
    {
        path: '/delivery',
        name: 'delivery',
        meta: { title: '配送' },
        redirect: '/delivery/storehouse',
        component: Main,
        children: [
            {
                path: '/delivery/storehouse',
                name: 'delivery_storehouse',
                meta: {
                    title: '仓库管理',
                    parentPath: '/delivery',
                    icon: 'icon_set_store',
                    permission: ['view']
                },
                component: () => import('@/views/delivery/storehouse.vue')
            },
            {
                path: '/delivery/community',
                name: 'delivery_community',
                meta: {
                    title: '小区管理',
                    parentPath: '/delivery',
                    icon: 'icon_dianpu_fenlei',
                    permission: ['view']
                },
                component: () => import('@/views/delivery/community.vue')
            },
            {
                path: '/delivery/community_edit',
                name: 'community_edit',
                meta: {
                    hidden: true,
                    title: '新增小区',
                    parentPath: '/delivery',
                    prevPath: '/delivery/community'
                },
                component: () => import('@/views/delivery/community_edit.vue')
            },
            {
                path: '/delivery/deliveryma',
                name: 'delivery_deliveryma',
                meta: {
                    title: '配送员管理',
                    parentPath: '/delivery',
                    icon: 'icon_user_guanli',
                    permission: ['view']
                },
                component: () => import('@/views/delivery/deliveryma.vue')
            },
            {
                path: '/delivery/deliveryma_edit',
                name: 'deliveryma_edit',
                meta: {
                    hidden: true,
                    title: '新增配送员',
                    parentPath: '/delivery',
                    prevPath: '/delivery/deliveryma'
                },
                component: () => import('@/views/delivery/deliveryma_edit.vue')
            },
            {
                path: '/delivery/deliveryma_config_cell',
                name: 'deliveryma_config_cell',
                meta: {
                    hidden: true,
                    title: '配送员配置小区',
                    parentPath: '/delivery',
                    prevPath: '/delivery/deliveryma'
                },
                component: () =>
                    import('@/views/delivery/deliveryma_config_cell.vue')
            },
            {
                path: '/delivery/report',
                name: 'delivery_report',
                meta: {
                    title: '配送报表',
                    parentPath: '/delivery',
                    icon: 'icon_order_guanli',
                    permission: ['view']
                },
                component: () => import('@/views/delivery/report.vue')
            },
            {
                path: '/delivery/report_detail',
                name: 'report_detail',
                meta: {
                    hidden: true,
                    title: '配送详情',
                    parentPath: '/delivery',
                    prevPath: '/delivery/report'
                },
                component: () => import('@/views/delivery/report_detail.vue')
            },
            {
                path: '/delivery/report_detail_detail',
                name: 'report_detail',
                meta: {
                    hidden: true,
                    title: '配送详细',
                    parentPath: '/delivery',
                    prevPath: '/delivery/report'
                },
                component: () =>
                    import('@/views/delivery/report_detail_detail.vue')
            },
            {
                path: '/delivery/storehouse_edit',
                name: 'storehouse_edit',
                meta: {
                    hidden: true,
                    title: '新增仓库',
                    parentPath: '/delivery',
                    prevPath: '/delivery/storehouse'
                },
                component: () => import('@/views/delivery/storehouse_edit.vue')
            }
            // {
            //     path: '/delivery/today_report',
            //     name: 'delivery_today_report',
            //     meta: {
            //         title: '今日配送报表',
            //         parentPath: '/delivery',
            //         icon: 'icon_dianpu_home',
            //         permission: ['view']
            //     },
            //     component: () => import('@/views/delivery/today_report.vue')
            // }
        ]
    }
]

export default routes
