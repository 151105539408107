// 开发环境域名

const host_development = 'http://ttd.rzshzx.com'
// const host_development = 'http://175.10.20.80:8182'
// const host_development = 'http://192.168.110.143:8083'
// 
export default {
    // 版本
    version: '1.7.0',
    baseURL: process.env.NODE_ENV == 'production' ? '' : host_development,
    tencentMapKey: 'FWEBZ-WHSHV-IRFPO-UNMRL-5EUWV-BFBFW'
}
